<template>
  <div class="subpage">
    <div class="title">
      <span>我的志愿表</span>
      <span>以下方案仅供参考，不作为决策依据，最终志愿填报需在各地教育部门指定网址进行填写。</span>
    </div>
    <div class="table">
      <el-table v-if="tableData" :cell-style="{ 'font-size': '16px' }" :header-cell-style="{
        'background-color': '#eeeeff',
        color: '#0F81FD',
        'letter-spacing': '3px',
      }" :data="tableData" border v-loading='loading'>
        <el-table-column prop="create_time" align="center" label="时间" width="200" />
        <el-table-column prop="batch" align="center" label="批次" width="199">
          <template slot-scope="scope">
            <p>{{ batchList[scope.row.batch - 1].name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="is_wenli" align="center" label="科类" width="180">
          <template slot-scope="scope">{{
            scope.row.is_wenli == 1 ? "文科" : "理科"
          }}</template>
        </el-table-column>
        <el-table-column prop="score" align="center" label="成绩" width="180" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <span @click="downReport(scope.row)" class="link">下载</span>
            <span class="del" @click="del(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination v-if="total > 0" :page-size="pageSize" :total="total" class="page-box" background
        layout="prev, pager, next" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: { ...mapState(["userInfo", 'batch']) },
  name: "MyVolunteer",
  data() {
    return {
      batchList: [
        { name: "本科一批", value: 1 },
        { name: "本科二批", value: 2 },
        { name: "高职高专批", value: 3 },
      ],
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$fecth
        .post("user_info/getReportList", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.count;
          this.loading = false;
        });
    },
    del(id) {
      this.loading = true;
      this.$fecth
        .post("user_info/delReport", {
          id: id,
        })
        .then(() => {
          this.getList();
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
          });
        });
    },
    downReport(e) {
      console.log(e.table)
      this.$router.push({
        path: "/PdfVolunteer",
        query: { table: JSON.parse(e.table) , userInfo: this.userInfo }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.subpage {
  background-color: #ffffff;

  .pagination {
    padding: 40px 0;

    .page-box {
      text-align: center;
    }
  }

  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    position: relative;
    border-bottom: #ddd solid 1px;

    span:last-child {
      font-size: 12px;
      position: absolute;
      color: #dedede;
      bottom: -15px;
      right: 0;
    }
  }

  .table {
    margin: 30px 0;
    padding: 0 20px;
    min-height: 340px;
  }

  .cell {
    font-size: 18px;
  }

  .link {
    color: #2066be;
  }

  .del {
    color: #2066be;
    margin-left: 10px;
    cursor: pointer;
  }
}</style>
